import React, { useCallback, useContext, useMemo, useState } from 'react';

import BasePlateContentsEditorDialog, {
  PlateOption,
} from 'client/app/components/Parameters/ChromatographyActions/BasePlateContentsEditorDialog';
import { EMPTY_WELL_GROUP_ID } from 'client/app/components/Parameters/ChromatographyActions/BasePlateContentsEditorWellGroupList';
import ChromatographyActionParameters from 'client/app/components/Parameters/ChromatographyActions/ChromatographyActionParameters';
import ChromatographyColourMethodSelector, {
  ChromatographyColorMethod,
} from 'client/app/components/Parameters/ChromatographyActions/ChromatographyColourMethodSelector';
import { WellParametersProps } from 'client/app/components/Parameters/ChromatographyActions/plateContentsEditorUtils';
import { AutocompleteParameterValuesContext } from 'client/app/state/AutocompleteParameterValuesContext';
import { loadVolumesToTotalAndFractionVolume } from 'common/lib/chromatography';
import { formatWellPosition } from 'common/lib/format';
import createDummyPlate from 'common/types/dummyPlates';
import { WellContents } from 'common/types/mix';
import { ChromatographyAction, RoboColumnContent } from 'common/types/robocolumns';
import Colors from 'common/ui/Colors';
import LiquidColors from 'common/ui/components/simulation-details/LiquidColors';
import { DialogProps } from 'common/ui/hooks/useDialog';

type Props = {
  value: Record<string, ChromatographyAction> | undefined;
  /**
   * Set to true if configuring a gradient chromatography.
   */
  isGradient?: boolean;
} & DialogProps<Record<string, ChromatographyAction> | undefined>;

const PLATE = createDummyPlate('96 wells');

const PLATE_OPTION: PlateOption = {
  label: 'RoboColumns',
  plate: PLATE,
};

/**
 * Given a normal or gradient chromatography action, generate a name to describe
 * the liquid being loaded into the RoboColumn.
 */
function getLoadLiquidName(action: ChromatographyAction): string {
  if ('LiquidToLoad' in action) {
    return action.LiquidToLoad || '';
  } else if ('BufferA' in action) {
    return (action.BufferA || '') + '/' + (action.BufferB || '');
  }
  return '';
}

/**
 * Bespoke version of the Plate Contents Editor that lets a user specify which liquids
 * should be dispensed into each robocolumn previously defined within the
 * Define_RoboColumn_Plate element.
 *
 * This handles both normal chromatography (loading a liquid into RoboColumns N times) and
 * gradient chromatography (loading a liquid of increasing concentration into RoboColumns
 * N times).
 */
export default function ChromatographyActionsDialog({
  isOpen,
  onClose,
  value,
  isGradient,
  isDisabled,
}: Props) {
  const liquidColors = useMemo(() => LiquidColors.createAvoidingAllColorCollisions(), []);

  // By default, the wells should be colored by their liquid contents. However,
  // the user can change this to color by load volume or residence time.
  const [colorMethod, setColorMethod] = useState<ChromatographyColorMethod>(
    ChromatographyColorMethod.LIQUIDTOLOAD,
  );

  // The color label is the string used to get a color.
  const getColorLabel = useCallback(
    (chromatographyAction: ChromatographyAction): string => {
      switch (colorMethod) {
        case 'LoadVolumePerFraction':
          return String(chromatographyAction?.LoadVolumes?.[0] || '');
        case 'ResidenceTime':
          return chromatographyAction?.ResidenceTime || '';
        default:
          return getLoadLiquidName(chromatographyAction);
      }
    },
    [colorMethod],
  );

  const generateWellInfo = useCallback(
    (chromatographyAction: ChromatographyAction): WellContents => ({
      // TODO: change this to filter_matrix_summary once patterns are implemented T2667
      kind: 'liquid_summary',
      id: '0',
      name: getColorLabel(chromatographyAction),
      total_volume: { value: 0, unit: '' },
    }),
    [getColorLabel],
  );

  const { optionsForType } = useContext(AutocompleteParameterValuesContext);

  const robocolumnsByWell = useMemo<Map<string, RoboColumnContent> | undefined>(() => {
    const roboColumnLayout = optionsForType<Record<string, RoboColumnContent>>(
      'github.com/Synthace/antha/Elements/Product/RoboColumns/Define_RoboColumn_Plate.RoboColumnLayout',
    )[0];
    return new Map(Object.entries(roboColumnLayout || {}));
  }, [optionsForType]);

  const disabledWells = useMemo<string[]>(() => {
    if (!robocolumnsByWell) {
      return [];
    }
    const wells = [];
    for (let row = 0; row < PLATE.rows; row++) {
      for (let col = 0; col < PLATE.columns; col++) {
        const wellPos = formatWellPosition(row, col);
        if (!robocolumnsByWell.get(wellPos)) {
          wells.push(wellPos);
        }
      }
    }
    return wells;
  }, [robocolumnsByWell]);

  const chromatographyActionParameters = useCallback(
    (editorProps: WellParametersProps<ChromatographyAction>) => (
      <ChromatographyActionParameters
        {...editorProps}
        robocolumnsByWell={robocolumnsByWell}
        isGradient={isGradient}
      />
    ),
    [isGradient, robocolumnsByWell],
  );

  const groupID = useCallback(
    (chromatographyAction: ChromatographyAction | undefined) =>
      chromatographyAction
        ? `${chromatographyAction.LoadVolumes?.join(',')} ${
            chromatographyAction.ResidenceTime
          } ${getLoadLiquidName(chromatographyAction)}`
        : EMPTY_WELL_GROUP_ID,
    [],
  );

  const groupProps = useCallback(
    (chromatographyAction: ChromatographyAction | undefined, wellsInGroup: string[]) => {
      // Look up the robocolumn for each well and summarise each. This map
      // stores the number of occurrences of each summary. We can use this to
      // show the robocolumns for the selected wells, e.g. "3 x Capto MMC 200ul,
      // 2 x PrismA 100ul".
      const robocolumnSummaries = new Map<string, number>();
      for (const wellLocation of wellsInGroup) {
        const robocolumn = robocolumnsByWell?.get(wellLocation);
        // If there is no robocolumn in this well, the summary can just show 'No
        // RoboColumn'
        const summary = robocolumn
          ? `${robocolumn.Resin} ${robocolumn.Volume}`
          : 'No RoboColumn';
        robocolumnSummaries.set(summary, (robocolumnSummaries.get(summary) || 0) + 1);
      }
      const loadColumnVolume = loadVolumesToTotalAndFractionVolume(
        chromatographyAction?.LoadVolumes,
      );
      const loadFraction = loadColumnVolume?.fraction ?? 0;
      const fractions = loadFraction ? (loadColumnVolume?.total ?? 0) / loadFraction : 0;
      const residenceTime = chromatographyAction?.ResidenceTime || '';
      const liquidName = chromatographyAction && getLoadLiquidName(chromatographyAction);
      const multiplierSign = '\u{00D7}';

      const countPrefix = (num: number, round = (n: number) => n.toString()) =>
        num !== 1 ? `${round(num)} ${multiplierSign} ` : '';
      const fractionsPrefix = countPrefix(fractions, n => n.toFixed(3));

      return {
        title: chromatographyAction
          ? `${liquidName} (${fractionsPrefix}${loadFraction} CV, ${residenceTime})`
          : 'No liquid to load',
        color: chromatographyAction
          ? liquidColors.getColorFromLiquidString(
              getColorLabel(chromatographyAction),
              false,
            )
          : Colors.WHITE,
        // Sort so highest count comes first, e.g. "3 x Capto MMC 200ul,
        // 2 x PrismA 100ul".
        subtitle: [...robocolumnSummaries]
          .sort(([_, countA], [__, countB]) => countB - countA)
          .map(([summary, count]) => `${countPrefix(count)}${summary}`)
          .join(', '),
      };
    },
    [getColorLabel, liquidColors, robocolumnsByWell],
  );

  const generateInitialChromatographyActions = useCallback(
    (
      wellLocations: string[],
      _: any,
      chromatographyActionToCopy: ChromatographyAction = {},
    ) =>
      new Map(
        wellLocations.map(wellLocation => [
          wellLocation,
          { ...chromatographyActionToCopy },
        ]),
      ),
    [],
  );

  const dialogTitle = `${isGradient ? 'Gradient ' : ''}Chromatography Actions`;

  return (
    <BasePlateContentsEditorDialog
      dialogTitle={dialogTitle}
      helpText="Click on plate locations where RoboColumns are located. You can select an entire row or column by clicking on the corresponding label. Drag to select a group."
      wellParameters={chromatographyActionParameters}
      selectedPlate={PLATE_OPTION}
      isOpen={isOpen}
      value={value}
      onClose={onClose}
      liquidColors={liquidColors}
      disabledWells={disabledWells}
      isDisabled={isDisabled}
      groupID={groupID}
      groupProps={groupProps}
      generateWellContents={generateInitialChromatographyActions}
      generateWellInfo={generateWellInfo}
      contentUnderPlate={
        // In some contexts, the user will be varying liquids in robocolumns. In
        // others, it may be residence time or load volume. So let the user
        // decide how wells should be colored.
        <ChromatographyColourMethodSelector
          value={colorMethod}
          onChange={setColorMethod}
        />
      }
    />
  );
}
