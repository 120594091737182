import React from 'react';

import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { ExtraPlateDescriptionProps } from 'client/app/components/Parameters/PlateDescriptionsEditor/PlateDescriptionEditor/PlateDescriptionEditor';
import { useWorkflowBuilderDispatch } from 'client/app/state/WorkflowBuilderStateContext';
import { pluralize } from 'common/lib/format';
import {
  defaultPlateSetDescription,
  PlateSetDescription,
} from 'common/types/plateSetDescription';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import TextField from 'common/ui/filaments/TextField';

type Props = {
  value: PlateSetDescription | null;
  onChange: (value: PlateSetDescription | null) => void;
  isDisabled: boolean;
  extraProps: ExtraPlateDescriptionProps;
  isActive?: boolean;
  warningSlot?: JSX.Element;
};

/**
 * PlateDescriptionParameter represents the plate name and button that launches
 * the PlateDescriptionContents in a panel or dialog
 *
 * Dependencies:
 *  - Workflow builder state for opening and closing the panel
 */
export default function PlateDescriptionParameter({
  value,
  onChange,
  isDisabled,
  extraProps,
  isActive = false,
  warningSlot,
}: Props) {
  const plateDescription = value ?? defaultPlateSetDescription();
  const dispatch = useWorkflowBuilderDispatch();

  const handleClick = () =>
    dispatch({
      type: 'openPlateDescriptionEditor',
      payload: {
        value: plateDescription,
        onChange,
        isDisabled,
        extraProps,
      },
    });

  const buttonText = plateDescription.plateTypeName
    ? `${plateDescription.plateTypeName}, ${getWellCountCopy(plateDescription)} selected`
    : 'Edit plate description';

  return (
    <Stack gap={2}>
      <TextField
        value={plateDescription.name}
        onChange={e => onChange({ ...plateDescription, name: e.target.value ?? '' })}
        placeholder="Plate name"
        disabled={isDisabled}
      />
      <EditButton
        variant="secondary"
        disabled={plateDescription.name === ''}
        onClick={handleClick}
        active={isActive}
      >
        <Typography variant="body2">{buttonText}</Typography>
      </EditButton>
      {warningSlot ?? null}
    </Stack>
  );
}

const EditButton = styled(Button, { shouldForwardProp: prop => prop !== 'active' })<{
  active: boolean;
}>(({ active, theme }) => ({
  padding: theme.spacing(3, 4),
  ...(active
    ? {
        background: Colors.BLUE_5,
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
      }
    : {}),
}));

function getWellCountCopy(plateDescription: PlateSetDescription) {
  const count = Object.values(plateDescription.regions).reduce<number>(
    (count, region) => count + region.wells.length,
    0,
  );
  return pluralize(count, 'well');
}
