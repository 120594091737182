import React from 'react';

import ChromatographyActionsDialog from 'client/app/components/Parameters/ChromatographyActions/ChromatographyActionsDialog';
import { GradientChromatographyAction } from 'common/types/robocolumns';
import { DialogProps } from 'common/ui/hooks/useDialog';

type Props = {
  value: Record<string, GradientChromatographyAction> | undefined;
} & DialogProps<Record<string, GradientChromatographyAction> | undefined>;

/**
 * Bespoke version of the Plate Contents Editor that lets a user specify which liquids
 * should be dispensed into each robocolumn previously defined within the
 * Define_RoboColumn_Plate element.
 *
 * This is the same as the ChromatographyActionsDialog except the user can specify two
 * liquids to load with each fraction with a different concentration of the two.
 */
export default function GradientChromatographyActionsDialog(props: Props) {
  return <ChromatographyActionsDialog {...props} isGradient />;
}
