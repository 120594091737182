import { useMemo } from 'react';

import { StepParamConfigById } from 'client/app/apps/protocols/context/StepsProvider';
import { ElementParamConfig } from 'client/app/apps/protocols/context/WorkflowProvider';
import { DECK_OPTIONS_COPY_OVERRIDES } from 'client/app/apps/workflow-builder/panels/workflow-settings/deck-options/deckOptionsCopyOverrides';
import {
  ElementParameterInterpolationConfig,
  interpolateConfiguredNames,
} from 'client/app/lib/workflow/format';
import {
  ElementContextMap,
  ElementErorrSeverity,
  ElementError,
} from 'common/types/bundle';

export type StepResponse = {
  errors: ElementError[];
  warnings: ElementError[];
  hasPreview?: boolean;
};

export type StepResponseById = {
  [stepId: string]: StepResponse;
} & {
  noStep: StepResponse;
  simulation: StepResponse;
};

export function useStepsResponse(
  stepsConfig: StepParamConfigById,
  elementsConfig: { [elementId: string]: ElementParamConfig },
  elementsContext: ElementContextMap,
  unformattedSimErrors?: SimulationError[],
): StepResponseById {
  // workflow element parameter configuration is applied by the element type not
  // element id. So it is safe to merge values to create a global config. This
  // is also unlikely to change so memo it
  const interpolationConfig = useMemo(() => {
    const result: ElementParameterInterpolationConfig = {};
    for (const { element, parameters } of Object.values(elementsConfig)) {
      const renames = Object.fromEntries(
        Object.entries(parameters).map(([name, displayName]) => [name, { displayName }]),
      );
      result[element.typeName] = {
        elementDisplayName: element.displayName,
        parameters: { ...result[element.typeName]?.parameters, ...renames },
      };
    }
    return result;
  }, [elementsConfig]);

  // however, protocol steps allow different parameter names for the same
  // element type. So we work out these overrides on a per elementId basis and
  // interpolate the errors
  const stepElementIds: string[] = [];
  const stepsResponse: { [stepId: string]: StepResponse } = {};
  for (const [stepId, stepConfig] of Object.entries(stepsConfig)) {
    const inputElementIds = Object.keys(stepConfig.inputs);
    const outputElementIds = Object.keys(stepConfig.outputs);
    stepElementIds.push(...inputElementIds, ...outputElementIds);

    const getConfig = getConfigWithOverridesByElement(
      interpolationConfig,
      elementsConfig,
      // elements don't tend to refer to outputs since the user cannot change an output
      stepConfig.inputs,
    );
    const errors = getElementErrors(inputElementIds, elementsContext, getConfig);
    const hasPreview = checkHasOutputPreview(outputElementIds, elementsContext);

    stepsResponse[stepId] = { ...errors, hasPreview };
  }

  // for errors that are not in any step, we use the default interpolation. This
  // could result in wrong parameter names if a non-step element refers to a
  // step element, but the chances of this happening are low
  const stepElementIdsSet = new Set(stepElementIds);
  const noStepElementIds = Object.keys(elementsContext).filter(
    id => !stepElementIdsSet.has(id),
  );
  const noStepErrors = getElementErrors(
    noStepElementIds,
    elementsContext,
    () => interpolationConfig,
  );

  // for simulation errors, we may get element errors, so we filter those out.
  // The remainder should not reference specific element parameters and so
  // general interpolation of the error message is ok
  const seenErrorCodes = new Set(
    Object.values(elementsContext).flatMap(c => c.errors.map(e => e.code)),
  );
  const simErrors = {
    errors:
      unformattedSimErrors
        ?.filter(e => e.code === null || !seenErrorCodes.has(e.code))
        .map(e => getSimulationError(e, interpolationConfig)) ?? [],
    warnings: [],
  };

  return { ...stepsResponse, noStep: noStepErrors, simulation: simErrors };
}

function getConfigWithOverridesByElement(
  interpolationConfig: ElementParameterInterpolationConfig,
  elementTypes: { [elementId: string]: { element: { typeName: string } } },
  paramOverrides?: { [elementId: string]: { [paramName: string]: string } },
): (elementId: string) => ElementParameterInterpolationConfig {
  return (elementId: string) => {
    const elementType = elementTypes[elementId]?.element.typeName;
    const overrides = paramOverrides?.[elementId];
    if (!elementType || !overrides) {
      return interpolationConfig;
    }

    const existing = interpolationConfig[elementType] ?? {
      elementDisplayName: elementType,
      parameters: {},
    };
    const renames = Object.fromEntries(
      Object.entries(overrides).map(([name, displayName]) => [name, { displayName }]),
    );
    return {
      ...interpolationConfig,
      [elementType]: {
        ...existing,
        parameters: { ...existing?.parameters, ...renames },
      },
    };
  };
}

function getElementErrors(
  elementIds: string[],
  elementContext: ElementContextMap,
  getInterpolationConfig: (elementId: string) => ElementParameterInterpolationConfig,
) {
  const allErrors = elementIds.flatMap(elementId => {
    const context = elementContext[elementId];
    if (context === undefined) return [];
    const config = getInterpolationConfig(elementId);
    return context.errors.map(error => ({
      code: error.code,
      message: interpolateConfiguredNames(error.message, config),
      severity: error.severity,
      messageType: error.messageType,
      details: error.details
        ? interpolateConfiguredNames(error.details, config)
        : undefined,
    }));
  });
  return {
    errors: allErrors.filter(e => e.severity === 'error'),
    warnings: allErrors.filter(e => e.severity === 'warning'),
  };
}

function getSimulationError(
  error: SimulationError,
  interpolationConfig: ElementParameterInterpolationConfig,
) {
  return {
    code: error.code ?? 'No error code',
    message: interpolateConfiguredNames(
      // we don't need to resolve non-element tokens in message_template since
      // the backend has done this automatically for a long time
      error.message_template ?? error.message,
      interpolationConfig,
      DECK_OPTIONS_COPY_OVERRIDES,
    ),
    severity: 'error' as ElementErorrSeverity, // simulations can never raising warnings
    messageType: error.message_type ?? 'text',
    details: error.details
      ? interpolateConfiguredNames(
          error.details,
          interpolationConfig,
          DECK_OPTIONS_COPY_OVERRIDES,
        )
      : undefined,
  };
}

function checkHasOutputPreview(elementIds: string[], elementContext: ElementContextMap) {
  return elementIds.some(elementId => {
    // we could also check context.outputs, but the status gives us an easier
    // yes/no answer since elements typically either run or fail.
    const context = elementContext[elementId];
    return !['neutral', 'error'].includes(context?.status);
  });
}
