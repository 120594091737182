import React, { MouseEventHandler, useCallback, useEffect } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import cx from 'classnames';

import { ScreenRegistry } from 'client/app/registry';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import IconButton from 'common/ui/components/IconButton';
import { IntersectionBar } from 'common/ui/components/IntersectionBar';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

export type PanelContent =
  | 'DOEBuilder'
  | 'DOEElementInstance'
  | 'ElementsBranch'
  | 'ElementGroup'
  | 'ElementInstance'
  | 'ElementsList'
  | 'SimulationError'
  | 'Simulations'
  | 'TagsFilter'
  | 'WorkflowSettings'
  | 'WorkflowStatus'
  | undefined;

/**
 * Additional panel content that is a child of the element instance panel
 */
type ElementInstancePanelAdditionalContent =
  | 'DeviceSelector'
  | 'DeckPositions'
  | 'PlateLayoutEditor'
  | 'PlateDescriptionEditor'
  | 'FilterPlateEditor'
  | 'FiltrationProtocolDesign'
  | 'OutputPreview';

/**
 * Additional panel content that is a child of the toolbar panels
 */
type ToolbarPanelsAdditionalContent = 'DeckOptions' | 'DeviceSelector';

export type AdditionalPanelContent =
  | ElementInstancePanelAdditionalContent
  | ToolbarPanelsAdditionalContent
  | undefined;

/**
 * Checks if the additionalPanel is a child of the element instance panel (i.e. if the additional
 * panel visibility is triggered by the user interacting with the element instance panel).
 *
 * @param additionalPanel The additional panel to check.
 * @returns true if additionalPanel is a child of the element instance.
 */
export function isAdditionalPanelChildOfElementInstance(
  additionalPanel: AdditionalPanelContent,
) {
  switch (additionalPanel) {
    case 'DeckOptions':
    case 'DeviceSelector':
      return false;
    default:
      return true;
  }
}

type Props = {
  /** Content to show in the main container of the panel. This is set to overflow:auto
   * to allow it to be scrollable by default.
   */
  children: React.ReactNode;
  /** Title text to be shown at the top of the panel. */
  title: string;
  /** Icon to show to the left of the title. */
  titleIcon?: JSX.Element;
  /** Content to show at the top of the panel that shouldn't be scrollable
   * (e.g. filters or search). The children will display underneath.
   */
  filters?: React.ReactElement;
  /** ClassName to apply to the Paper component that wraps the panel. */
  className?: string;
  /** If specified, a close button will be shown to the right of the title, and this
   * handler will be called when the user clicks that button.
   */
  onClose?: () => void;
  /** Whether to show the MUI CloseIcon or the text copy 'DONE'. */
  onCloseVariant?: 'icon' | 'done';
  /** Ref attached to the scrollContainer div that wraps the children. */
  scrollableRef?: React.RefObject<HTMLDivElement>;
  /** Ref attached to the panel Paper component. */
  paperRef?: React.RefObject<HTMLDivElement>;
  /** Applies an offset using margin-top to the Panel. */
  offSetY?: number;
  /** Indicate if the panel should be in an error state. */
  hasError?: boolean;
  /** Allow for handling what happens if there is a mouse over on title */
  onMouseEnterTitle?: MouseEventHandler;
  onMouseLeaveTitle?: MouseEventHandler;
  /**
   * Allow for what happens when scrolling of the panel is done
   */
  onScroll?: () => void;
  /** Panel content for logging purposes */
  panelContent: PanelContent | AdditionalPanelContent;
  panelActions?: React.ReactElement; //These should be sticky and at the bottom of the panel.
  fullWidth?: boolean; //Allow panel to not fixed to the default width for panel.
};

type BaseProps = React.PropsWithChildren<Omit<Props, 'scrollableRef' | 'onScroll'>>;

export const BasePanel = React.memo(function BasePanel(props: BaseProps) {
  const classes = useStyles();
  const {
    title,
    className,
    titleIcon,
    filters,
    onClose,
    onCloseVariant,
    offSetY,
    hasError,
    onMouseEnterTitle,
    onMouseLeaveTitle,
    panelContent,
    panelActions,
    fullWidth,
    children,
    paperRef,
  } = props;

  // For general logging purposes on when the Panel is mounted/unmounted.
  // Most times this will be the result of a user-action - but sometimes we
  // mount Panels automatically (e.g. on simulation error).
  useEffect(() => {
    logEvent('panel-opened', ScreenRegistry.WORKFLOW, panelContent);
    return () => {
      logEvent('panel-closed', ScreenRegistry.WORKFLOW, panelContent);
    };
  }, [panelContent]);

  const handleClosePanel = useCallback(() => {
    if (onClose) {
      logEvent('panel-closed-by-close-button', ScreenRegistry.WORKFLOW, panelContent);
      onClose();
    }
  }, [onClose, panelContent]);

  const closeVariant = onCloseVariant ?? 'icon';

  return (
    <Grow in unmountOnExit>
      <Paper
        className={cx(classes.panel, className, {
          [classes.fixedWidth]: !fullWidth,
        })}
        elevation={4}
        style={{ marginTop: `${offSetY}px` }}
        ref={paperRef}
      >
        <div className={cx(classes.title, { [classes.errorTitle]: hasError })}>
          <div
            className={cx(classes.titleWithIcon, {
              [classes.errorContrast]: hasError,
            })}
          >
            {titleIcon}
            <Typography
              variant="h5"
              noWrap
              className={hasError ? classes.errorContrast : undefined}
              onMouseEnter={onMouseEnterTitle}
              onMouseLeave={onMouseLeaveTitle}
            >
              {title}
            </Typography>
          </div>
          {onClose &&
            (closeVariant === 'done' ? (
              <Button
                className={cx({
                  [classes.errorCloseButton]: hasError,
                })}
                size="small"
                onClick={handleClosePanel}
                variant="tertiary"
                color="primary"
              >
                Done
              </Button>
            ) : (
              <IconButton
                className={hasError ? classes.errorCloseButton : classes.closeIcon}
                icon={<CloseIcon />}
                size="xsmall"
                onClick={handleClosePanel}
              />
            ))}
        </div>
        {filters && <div className={classes.filters}>{filters}</div>}
        {children}
        {panelActions && <div className={classes.panelActions}>{panelActions}</div>}
      </Paper>
    </Grow>
  );
});

/**
 * Panel is a container used to display overlaying content
 * in the workflow builder (for example, the toolbar panels in the ControlOverlay
 * component, or the ElementInstancePanel).
 *
 * By default the Panel will animate on mounting using the MUI Grow. It will not
 * animate on unmounting.
 */
export default React.memo(function Panel(props: Props) {
  const classes = useStyles();
  const { children, scrollableRef, onScroll, ...restProps } = props;

  const scrollableContent = (
    <div className={classes.scrollContainer} ref={scrollableRef} onScroll={onScroll}>
      <IntersectionBar />
      {children}
    </div>
  );

  return <BasePanel {...restProps}>{scrollableContent}</BasePanel>;
});

/**
 * Same as the default export, with 2 differences:
 * - the content is not scrollable,
 * - there is no intersection bar.
 */
export const PanelWithoutScroll = React.memo(function PanelWithoutScroll(
  props: Omit<Props, 'onScroll' | 'scrollableRef'>,
) {
  const classes = useStyles();
  const { children, ...restProps } = props;

  return (
    <BasePanel {...restProps}>
      <div className={classes.panelContentNoScroll}>{children}</div>
    </BasePanel>
  );
});

const useStyles = makeStylesHook(theme => ({
  closeIcon: {
    color: Colors.TEXT_PRIMARY,
  },
  filters: {
    padding: theme.spacing(3),
  },
  fixedWidth: {
    width: '296px',
  },
  panel: {
    backgroundColor: Colors.WHITE,
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
  },
  panelActions: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(0),
  },
  panelContentNoScroll: {
    padding: theme.spacing(3),
    flex: '1 1 auto',
    minHeight: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  scrollContainer: {
    overflowX: 'hidden',
    overflowY: 'auto',
    borderBottomRightRadius: 'inherit',
    borderBottomLeftRadius: 'inherit',
    marginBottom: theme.spacing(3),
    flex: '1 1 auto',
  },
  title: {
    alignItems: 'center',
    backgroundColor: Colors.BLUE_0,
    borderTopLeftRadius: 'inherit',
    borderTopRightRadius: 'inherit',
    display: 'flex',
    flexShrink: 0,
    justifyContent: 'space-between',
    padding: theme.spacing(4, 3),
    height: '40px',
  },
  errorTitle: {
    backgroundColor: theme.palette.error.main,
  },
  errorContrast: {
    color: theme.palette.error.contrastText,
  },
  errorCloseButton: {
    color: theme.palette.error.contrastText,
    '&:hover': {
      backgroundColor: Colors.DARK_ACTION_HOVER,
    },
  },
  titleWithIcon: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '& >:not(:first-child)': {
      marginLeft: theme.spacing(3),
    },
  },
}));
