import { useEffect, useRef, useState } from 'react';

import PlateDescriptionEditorDialog from 'client/app/components/Parameters/PlateDescriptionsEditor/PlateDescriptionEditorDialog';
import PlateLayoutEditorDialog from 'client/app/components/Parameters/PlateLayout/PlateLayoutEditorDialog';
import useEntityConflictErrorDialog from 'client/app/hooks/useEntityConflictErrorDialog';
import {
  useWorkflowBuilderDispatch,
  useWorkflowBuilderSelector,
} from 'client/app/state/WorkflowBuilderStateContext';
import { ErrorCodes } from 'common/types/errorCodes';
import { ProtocolStep } from 'common/types/Protocol';
import useDialog from 'common/ui/hooks/useDialog';
import { useStateWithURLParams } from 'common/ui/hooks/useStateWithURLParams';

/**
 * Handles the opening and closing of complex parameter editor dialogs.
 * Relies on triggers in workflowBuilderStateContext. This does now allow
 * modifying the content of any of the dialogs, only the rendering of them.
 *
 * @returns Array of dialog JSX elements to render
 */
export function useComplexParameterEditorDialogManager() {
  // Currently when a dialog is triggered, then the state for additionalPanel is set
  // to be the value of the dialog to open. This was built to be used within the Builder
  // component. Here, we are re-using that logic to determine when to open one of the
  // complex parameter dialogs, because the underlying trigger components (i.e. buttons)
  // we are using in Protocols are the same as those in the Builder.
  const additionalPanel = useWorkflowBuilderSelector(state => state.additionalPanel);
  const dispatch = useWorkflowBuilderDispatch();

  const [plateDescriptionEditorDialog, openPlateDescriptionEditorDialog] = useDialog(
    PlateDescriptionEditorDialog,
  );
  const [plateLayoutEditorDialog, openPlateLayoutEditorDialog] = useDialog(
    PlateLayoutEditorDialog,
  );

  useEffect(() => {
    const resetPanel = () => {
      dispatch({ type: 'setAdditionalPanel', payload: undefined });
    };
    const handleOpenDialog = async () => {
      switch (additionalPanel) {
        case 'PlateDescriptionEditor':
          await openPlateDescriptionEditorDialog({});
          resetPanel();
          break;
        case 'PlateLayoutEditor':
          await openPlateLayoutEditorDialog({});
          resetPanel();
          break;
      }
    };

    void handleOpenDialog();
  }, [
    additionalPanel,
    dispatch,
    openPlateDescriptionEditorDialog,
    openPlateLayoutEditorDialog,
  ]);

  return [plateLayoutEditorDialog, plateDescriptionEditorDialog];
}

const PROTOCOL_SELECTED_STEP_ID_PARAM = 'selected_step';
const PROTOCOL_EXPANDED_LIST_PARAM = 'expand_input_list';

/**
 * Handles storing state in URL for parameters relating to the Protocols UI.
 *
 * @returns State setters for each URL param
 */
export function useProtocolsParamState(steps: ProtocolStep[]): {
  selectedStep?: ProtocolStep;
  handleSelectStep: (stepId: string) => void;
  expandInputList?: boolean;
  handleSetExpandInputList: () => void;
} {
  const [selectedStepId, setSelectedStepId] = useStateWithURLParams({
    paramName: PROTOCOL_SELECTED_STEP_ID_PARAM,
    paramType: 'string',
  });

  const [expandInputList, setExpandInputList] = useStateWithURLParams({
    paramName: PROTOCOL_EXPANDED_LIST_PARAM,
    paramType: 'boolean',
  });

  const handleSelectStep = (stepId: string) => {
    setSelectedStepId(stepId);
  };

  const handleSetExpandInputList = () => {
    setExpandInputList(!expandInputList);
  };

  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    if (steps.length > 1) {
      setExpandInputList(true);
    }
  }, [setExpandInputList, steps.length]);

  // if the steps change and no longer contains the selectedStepId gracefully
  // fallback to the closest step
  useEffect(() => {
    if (steps.length === 0) {
      return;
    }
    const index = steps.findIndex(({ id }) => id === selectedStepId);
    if (index > -1) {
      setSelectedIndex(index);
      return;
    }
    // ensure index and step id are matched to prevent infinite loops
    const fallbackIndex = selectedIndex - 1 > -1 ? selectedIndex - 1 : 0;
    setSelectedIndex(fallbackIndex);
    setSelectedStepId(steps[fallbackIndex].id);
  }, [steps, selectedStepId, selectedIndex, setSelectedStepId]);

  return {
    selectedStep: steps[selectedIndex],
    handleSelectStep,
    expandInputList,
    handleSetExpandInputList,
  };
}

/**
 * Manages asynchronous entity updates such that:
 *  1. multiple `handleUpdate` calls are not issued at once
 *  2. `handleUpdate` is retriggered if `setUpdateRequired(true)` or
 *     `editVersion` / `handleUpdate` are updated
 *
 * This hook is useful to minimise editVersion conflicts on updates
 */
export function useUpdateEntity(args: {
  entityType: string;
  editVersion: number;
  conflictCode: ErrorCodes;
  handleUpdate: (editVersion: number) => Promise<void>;
}) {
  const { entityType, editVersion, conflictCode, handleUpdate } = args;

  // must use isUpdating rather than e.g. the apollo loading state of an entity
  // update. The latter will be true if useEffect is triggered twice at once
  // because `handleUpdate` is called async
  const isUpdating = useRef(false);
  const [updateRequired, setUpdateRequired] = useState(false);
  const { handleCheckConflictError, conflictDialog } = useEntityConflictErrorDialog();

  useEffect(() => {
    if (isUpdating.current || !updateRequired || conflictDialog) return;
    isUpdating.current = true;
    // while updating, the caller could set isUpdateRequired true, which will
    // cause the effect to be triggered _only_ once the current update is
    // complete _and_ if the current handleUpdate call increases the editVersion
    setUpdateRequired(false);

    (async () => {
      try {
        await handleUpdate(editVersion);
      } catch (error) {
        await handleCheckConflictError(error, entityType, conflictCode);
      } finally {
        isUpdating.current = false;
      }
    })();
  }, [
    conflictDialog,
    entityType,
    conflictCode,
    handleCheckConflictError,
    handleUpdate,
    editVersion,
    updateRequired,
  ]);

  return {
    setUpdateRequired,
    conflictDialog,
  };
}
