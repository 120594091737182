import React, { useState } from 'react';

import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import { usePlatesByType } from 'client/app/api/PlateTypesApi';
import { ActionsMenu } from 'client/app/components/Parameters/PlateDescriptionsEditor/PlateDescriptionEditor/ActionsMenu';
import {
  CONTENTS_LIST_WIDTH,
  PANEL_WRAP_WIDTH,
} from 'client/app/components/Parameters/PlateDescriptionsEditor/PlateDescriptionEditor/dimensions';
import { Header } from 'client/app/components/Parameters/PlateDescriptionsEditor/PlateDescriptionEditor/Header';
import { PlateRegionCard } from 'client/app/components/Parameters/PlateDescriptionsEditor/PlateDescriptionEditor/PlateRegionCard';
import {
  PlateRegionParameters,
  PlateRegionRenderProps,
} from 'client/app/components/Parameters/PlateDescriptionsEditor/PlateDescriptionEditor/PlateRegionParameters';
import { PlateSelector } from 'client/app/components/Parameters/PlateDescriptionsEditor/PlateDescriptionEditor/PlateSelector';
import { PlateWellSelector } from 'client/app/components/Parameters/PlateDescriptionsEditor/PlateDescriptionEditor/PlateWellSelector';
import { useContainerResize } from 'client/app/components/Parameters/PlateDescriptionsEditor/PlateDescriptionEditor/useContainerResize';
import { usePlateDescriptionState } from 'client/app/components/Parameters/PlateDescriptionsEditor/PlateDescriptionEditor/usePlateDescriptionState';
import { getAllWells } from 'client/app/components/Parameters/PlateDescriptionsEditor/PlateDescriptionEditor/utils';
import { WellSelectionActions } from 'client/app/components/Parameters/PlateDescriptionsEditor/PlateDescriptionEditor/WellSelectionActions';
import {
  defaultPlateRegion,
  ExtraPlateRegionParameters,
  PlateRegion,
} from 'common/types/plateSetDescription';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';

type Props = {
  onClose?: () => void;
  /**
   * Used to detect client width to adjust content wrap.
   */
  containerRef: React.RefObject<HTMLDivElement>;
};

export type ExtraPlateDescriptionProps = {
  RegionNameComponent: React.FC<PlateRegionRenderProps<string>>;
  ExtraParametersComponent?: React.FC<PlateRegionRenderProps<ExtraPlateRegionParameters>>;
  elementInstanceId?: string;
};

export default function PlateDescriptionEditor({ containerRef, onClose }: Props) {
  const [selectedWells, setSelectedWells] = useState<string[]>([]);
  const [getPlateType] = usePlatesByType();
  const isNarrowWidth = useContainerResize({ containerRef, widthWrap: PANEL_WRAP_WIDTH });

  const {
    renderRegionName,
    renderExtraParameters,
    plateDescription: initialPlateDescription,
    plateType,
    liquidColors,
    getColor,
    updatePlateType,
    updatePlateRegion,
    deletePlateRegions,
    deletionDialog,
    isDisabled,
    isLayoutOptimised,
  } = usePlateDescriptionState(getPlateType);

  const canSelectWells = !isLayoutOptimised;
  const plateDescription = canSelectWells
    ? initialPlateDescription
    : { ...initialPlateDescription, regions: {} };
  const allRegionNames = Object.keys(plateDescription.regions);
  const canDeleteRegions = allRegionNames.length > 0 && !isDisabled;

  const [editRegionName, setEditRegionName] = useState<string | undefined>();
  const isEditingRegion = editRegionName !== undefined;
  const handleCloseEditingRegion = () => {
    setSelectedWells([]);
    setEditRegionName(undefined);
  };
  const handleSaveEditingRegion = (newName: string, updatedRegion: PlateRegion) => {
    const resolveWellConflicts = true;
    const isNamedUpdated = editRegionName !== newName;
    const removeOldEntry = isNamedUpdated && !!editRegionName; // without confirmation
    removeOldEntry && updatePlateRegion(editRegionName, { ...updatedRegion, wells: [] });
    updatePlateRegion(newName, updatedRegion, resolveWellConflicts);
    handleCloseEditingRegion();
  };

  return (
    <>
      <Header title={plateDescription.name} onClose={onClose} />
      <Container isNarrowWidth={isNarrowWidth}>
        <PlateSelector
          nameWithRiser={plateDescription.plateTypeName}
          onChange={updatePlateType}
          isDisabled={isDisabled}
        />
        <SpacingDivider />
        <SubContainer isNarrowWidth={isNarrowWidth}>
          {plateType && (
            <SelectionContainer>
              {canSelectWells ? (
                <WellSelectionActions
                  numWellsSelected={selectedWells.length}
                  onCreateRegion={() => setEditRegionName('')}
                  onClearSelection={() => setSelectedWells([])}
                  onSelectAll={() => setSelectedWells(getAllWells(plateType))}
                  isDisabled={isDisabled || isEditingRegion}
                />
              ) : (
                <Alert severity="info">
                  Liquids will be automatically assigned across the entire plate due to
                  other options selected.
                </Alert>
              )}
              <PlateWellSelector
                plateDescription={plateDescription}
                plateType={plateType}
                selectedWells={selectedWells}
                onChangeWells={setSelectedWells}
                liquidColors={liquidColors}
                getColor={getColor}
                isDisabled={isDisabled || isEditingRegion || !canSelectWells}
              />
            </SelectionContainer>
          )}
          <ListContainer isNarrowWidth={isNarrowWidth}>
            {isEditingRegion ? (
              <PlateRegionParameters
                region={{
                  ...defaultPlateRegion(),
                  ...plateDescription.regions?.[editRegionName],
                  wells: selectedWells,
                }}
                // while the user is editing the parameters ensure the color is
                // stable until the parameters are closed by passing a fixed color
                color={getColor(editRegionName)}
                name={editRegionName}
                onEdit={setSelectedWells}
                onSave={handleSaveEditingRegion}
                onCancel={handleCloseEditingRegion}
                otherRegionNames={allRegionNames.filter(n => n !== editRegionName)}
                renderRegionName={renderRegionName}
                renderExtraParameters={renderExtraParameters}
                isDisabled={isDisabled}
              />
            ) : (
              <>
                {canDeleteRegions && (
                  <DeleteButton
                    variant="tertiary"
                    onClick={() => deletePlateRegions(allRegionNames)}
                  >
                    Delete all regions
                  </DeleteButton>
                )}
                <Stack gap={3} overflow="auto">
                  {Object.entries(plateDescription.regions).map(
                    ([regionName, region]) => (
                      <PlateRegionCard
                        key={regionName}
                        color={getColor(regionName)}
                        name={regionName}
                        region={region}
                        selectedWells={selectedWells}
                        onUpdateRegion={(...props) => {
                          updatePlateRegion(...props);
                          setSelectedWells([]);
                        }}
                        renderActionsMenu={(anchorEl, onClose) => (
                          <ActionsMenu
                            anchorEl={anchorEl}
                            onEdit={() => {
                              // focus on active wells when editing region parameters
                              setSelectedWells(region.wells);
                              setEditRegionName(regionName);
                            }}
                            onDelete={() => deletePlateRegions([regionName])}
                            onClose={onClose}
                            isDisabled={isDisabled}
                          />
                        )}
                      />
                    ),
                  )}
                </Stack>
              </>
            )}
          </ListContainer>
        </SubContainer>
        {deletionDialog}
      </Container>
    </>
  );
}

const Container = styled('div', {
  shouldForwardProp: props => props !== 'isNarrowWidth',
})<{ isNarrowWidth: boolean }>(({ theme, isNarrowWidth }) => ({
  gridArea: 'main',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  padding: theme.spacing(3, 5, 5, 5),
  ...(isNarrowWidth ? { overflowY: 'auto' } : {}),
}));

const SpacingDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(5, 0),
}));

const SubContainer = styled('div', {
  shouldForwardProp: props => props !== 'isNarrow',
})<{ isNarrowWidth: boolean }>(({ theme, isNarrowWidth }) => ({
  display: 'flex',
  gap: theme.spacing(5),
  // We set minHeight here so this div takes up only the rest of the parent height.
  // Flex items by default will set the min-height to be the content, but
  // that won't work for us as we want the plate contents list to overflow
  // and the well selector to be contained within the panel - i.e. we don't want this
  // div to take up the height of the contents, but the height of the rest of the parent.
  minHeight: 0,
  ...(isNarrowWidth ? { flexWrap: 'wrap', justifyContent: 'center' } : {}),
}));

const SelectionContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  height: '100%',
});

const ListContainer = styled('div', {
  shouldForwardProp: props => props !== 'isNarrowWidth',
})<{ isNarrowWidth: boolean }>(({ isNarrowWidth }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: `${CONTENTS_LIST_WIDTH}px`,
  ...(isNarrowWidth ? { height: 'auto' } : {}),
}));

const DeleteButton = styled(Button)(({ theme }) => ({
  color: Colors.ERROR_MAIN,
  marginBottom: theme.spacing(5),
  marginLeft: 'auto',
}));
