import React, { useEffect } from 'react';

import { styled } from '@mui/material/styles';

import { useProtocolContext } from 'client/app/apps/protocols/context/ProtocolProvider';
import { useStepsContext } from 'client/app/apps/protocols/context/StepsProvider';
import { useWorkflowContext } from 'client/app/apps/protocols/context/WorkflowProvider';
import EditableName from 'client/app/apps/protocols/EditableName';
import { InputStepList } from 'client/app/apps/protocols/InputStepList';
import {
  InputStepParameters,
  ParameterDescriberList,
  ParameterSkeletonList,
} from 'client/app/apps/protocols/InputStepParameters';
import { useComplexParameterEditorDialogManager } from 'client/app/apps/protocols/lib/utils';
import {
  NoOutputStepPreview,
  OutputStepPreview,
  OutputStepPreviewPanel,
  OutputStepPreviewSkeleton,
  OutputStepPreviewTitle,
} from 'client/app/apps/protocols/OutputStepPreview';
import { isDefined } from 'common/lib/data';
import doNothing from 'common/lib/doNothing';
import { ProtocolStepInput } from 'common/types/Protocol';
import { Schema } from 'common/types/schema';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';

type Props = {
  /**
   * navigateBack should enable navigation back to the previous stage of
   * protocol creation
   */
  navigateBack: () => void;
};

export const PersonaliseProtocol = ({ navigateBack }: Props) => {
  // loading of step state is what is important; not whether mutations of
  // protocol or workflow entities have been completed or not. Relying on the
  // latter would cause the skeleton to be shown every time we update the steps
  const {
    protocolSteps,
    workflowSchema,
    selectedStep,
    updateStepName,
    updateStepInput,
    reorderStepInputs,
  } = useStepsContext();
  const { workflowConfig, updateOutput } = useWorkflowContext();
  const loading = protocolSteps.length === 0;
  const activeOutput = selectedStep?.outputs[0];
  const dialogs = useComplexParameterEditorDialogManager();

  const { displayDescription, updateProtocol } = useProtocolContext();
  useEffect(() => {
    // any time step state is updated by any child component queue a protocol update
    updateProtocol({ protocol: { displayDescription, steps: protocolSteps } });
  }, [displayDescription, protocolSteps, updateProtocol]);

  // awkwardly element output preview depends on workflow builder context.
  // Therefore we can't simply pass the correct props to OutputStepPreview
  useEffect(() => {
    activeOutput && updateOutput(activeOutput.id);
  }, [activeOutput, updateOutput]);

  const stepName = selectedStep
    ? selectedStep.displayName
    : loading
    ? 'Loading step...'
    : 'Unnamed Step';

  const parameterList =
    loading || selectedStep === undefined ? (
      <ParameterSkeletonList />
    ) : (
      <ParameterDescriberList
        inputParameters={getSchemaParameters(selectedStep.inputs, workflowSchema)}
        workflowConfig={workflowConfig}
        updateDescription={updateStepInput(selectedStep)}
        updateOrder={reorderStepInputs(selectedStep)}
      />
    );

  const outputTitle = activeOutput
    ? `Preview ${activeOutput.displayName}`
    : loading
    ? 'Loading preview...'
    : 'Preview';

  const outputPanelContent = loading ? (
    <OutputStepPreviewSkeleton />
  ) : selectedStep && selectedStep.outputs.length > 0 ? (
    <OutputStepPreview />
  ) : (
    <NoOutputStepPreview
      callToAction={
        <Button variant="secondary" onClick={navigateBack}>
          + ADD OUTPUT
        </Button>
      }
    />
  );

  return (
    <Wrapper>
      <InputStepList isDraggable alwaysExpandList />
      <InputsAndOutputsWrapper>
        <InputStepParameters
          header={
            <EditableName
              name={stepName}
              onSave={selectedStep ? updateStepName(selectedStep) : doNothing}
            />
          }
        >
          {parameterList}
        </InputStepParameters>
        <OutputStepPreviewPanel noOutput={activeOutput === undefined}>
          <OutputStepPreviewTitle variant="h4">{outputTitle}</OutputStepPreviewTitle>
          {outputPanelContent}
        </OutputStepPreviewPanel>
      </InputsAndOutputsWrapper>
      {dialogs}
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplate: `
      "list inputsAndOutputs inputsAndOutputs" minmax(400px, 1200px)
      / auto 1fr 1fr`,
  padding: theme.spacing(8),
  gap: theme.spacing(7),
  height: '100%',
  overflow: 'auto',
  backgroundColor: Colors.GREY_10,
  position: 'relative',
}));

const InputsAndOutputsWrapper = styled('div')({
  gridArea: 'inputsAndOutputs',
  display: 'flex',
  overflowX: 'auto',
  zIndex: 1,
});

function getSchemaParameters(stepInputs: ProtocolStepInput[], schema: Schema) {
  return stepInputs
    .map(stepInput => {
      const schemaInput = schema.inputs?.find(i => i.id === stepInput.id);
      return schemaInput ? { ...stepInput, value: schemaInput.default } : undefined;
    })
    .filter(isDefined);
}
