import React, { useMemo } from 'react';

import ChromatographyActionsDialog from 'client/app/components/Parameters/ChromatographyActions/ChromatographyActionsDialog';
import { AppIcon } from 'client/app/icons';
import { pluralize } from 'common/lib/format';
import { BasicChromatographyAction } from 'common/types/robocolumns';
import { ParameterEditorBaseProps } from 'common/ui/components/ParameterEditorBaseProps';
import SelectFromDialogButton from 'common/ui/components/SelectFromDialogButton';

type Props = {
  onChange: (newValue?: Record<string, BasicChromatographyAction>) => void;
} & ParameterEditorBaseProps<Record<string, BasicChromatographyAction>>;

/**
 * Opens a dialog to configure the chromatography actions, which defines the
 * liquid to load into a robocolumn N times to produce N fractions.
 */
export default function ChromatographyActionsLayoutEditor({
  value,
  onChange,
  isDisabled,
}: Props) {
  const label = useMemo(
    () =>
      value ? pluralize(Object.keys(value).length, 'chromatography action') : undefined,
    [value],
  );

  const dialogProps = useMemo(() => ({ value, isDisabled }), [isDisabled, value]);

  const placeholder = isDisabled ? 'None Configured' : 'Configure';

  return (
    <SelectFromDialogButton
      selectedValueLabel={label}
      value={value}
      icon={<AppIcon iconId="antha:plate" />}
      dialog={ChromatographyActionsDialog}
      dialogProps={dialogProps}
      placeholder={placeholder}
      onChange={onChange}
    />
  );
}
