import React, {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from 'react';

import { useUpdateProtocolInstance } from 'client/app/apps/protocols/api/ProtocolsAPI';
import { useUpdateEntity } from 'client/app/apps/protocols/lib/utils';
import { ProtocolInstanceQuery } from 'client/app/gql';
import { ErrorCodes } from 'common/types/errorCodes';
import { ProtocolStepInput } from 'common/types/Protocol';

type FetchedProtocolInstance = NonNullable<
  ProtocolInstanceQuery['protocolInstance']['instance']
>;

// omit parameters since the provider returns a local copy
type ProtocolInstance = Omit<FetchedProtocolInstance, 'parameters'>;

type ProtocolInstanceContextType = {
  loading: boolean;
  protocolInstance: ProtocolInstance;
  updateProtocolInput: (input: ProtocolStepInput, value: any) => void;
  getInputWithValue: (input: ProtocolStepInput) => ProtocolStepInput & { value: any };
  updateConflictDialog: JSX.Element | null;
};

export const ProtocolInstanceContext = createContext<
  ProtocolInstanceContextType | undefined
>(undefined);

type ProtocolInstanceProviderProps = {
  instance: FetchedProtocolInstance;
} & PropsWithChildren;

export const useProtocolInstanceContext = () => {
  const context = useContext(ProtocolInstanceContext);

  if (context === undefined) {
    throw new Error(
      'useProtocolInstanceContext must be used within a ProtocolInstanceProvider',
    );
  }

  return context;
};

export const ProtocolInstanceProvider: FC<ProtocolInstanceProviderProps> = ({
  instance,
  children,
}) => {
  const [protocolParameters, setProtocolParameters] = useState(instance.parameters);
  const { handleUpdateProtocolInstance, loading } = useUpdateProtocolInstance(
    instance.id,
  );

  const { conflictDialog, setUpdateRequired } = useUpdateEntity({
    entityType: 'protocol instance',
    editVersion: instance.editVersion,
    conflictCode: ErrorCodes.PROTOCOL_INSTANCE_EDIT_CONFLICT,
    handleUpdate: useCallback(
      async (editVersion: number) => {
        await handleUpdateProtocolInstance(editVersion, { params: protocolParameters });
      },
      [handleUpdateProtocolInstance, protocolParameters],
    ),
  });

  const updateProtocolInput = useCallback(
    (input: ProtocolStepInput, value: any) => {
      // important to set null so the protocol input key is retained and set with
      // a null value during serialisation, otherwise the value is not deleted
      // from the perspective of validating instance updates
      setProtocolParameters(prev => {
        const newValue = value ? value : null;
        const linkedUpdates = input.linked?.map<[string, any]>(id => [id, newValue]);
        return {
          ...prev,
          ...Object.fromEntries(linkedUpdates || []),
          [input.id]: newValue,
        };
      });
      setUpdateRequired(true);
    },
    [setUpdateRequired],
  );

  const getInputWithValue = (input: ProtocolStepInput) => {
    return { ...input, value: protocolParameters[input.id] };
  };

  const state = {
    loading,
    protocolInstance: instance,
    getInputWithValue,
    updateProtocolInput,
    updateConflictDialog: conflictDialog,
  };

  return (
    <ProtocolInstanceContext.Provider value={state}>
      {children}
    </ProtocolInstanceContext.Provider>
  );
};
