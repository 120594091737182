import React, { useCallback } from 'react';

import ParameterEditorWithHelperText from 'client/app/components/Parameters/ParameterEditor';
import { ParameterValue, WorkflowConfig } from 'common/types/bundle';
import { ParameterEditorConfigurationSpec } from 'common/types/commonConfiguration';

type Props = {
  editor: ParameterEditorConfigurationSpec;
  value: any;
  onChange: (value: ParameterValue) => void;
  elementInstanceId: string;
  workflowConfig: WorkflowConfig;
  isDisabled?: boolean;
};

export function ProtocolElementParameter({
  editor,
  value,
  onChange,
  elementInstanceId,
  workflowConfig,
  isDisabled,
}: Props) {
  const handleChange = useCallback(
    (value: ParameterValue, _?: string) => onChange(value),
    [onChange],
  );

  return (
    <ParameterEditorWithHelperText
      // no anthaType since we have set the correct overrides in additionalProps
      // for autosuggestions
      anthaType=""
      onChange={handleChange}
      elementInstanceId={elementInstanceId}
      editorType={editor.type}
      editorProps={editor.additionalProps || undefined}
      workflowConfig={workflowConfig}
      placeholder={editor.placeholder}
      value={value}
      isDisabled={isDisabled}
    />
  );
}
